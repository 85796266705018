import { useNavigate } from "react-router-dom";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { AuthContext, AuthContextType, LangContext, LangContextType, useAsync } from "../../../utilities/customHooks";
import { useContext, useState } from "react";
import { DetailsContentHeader } from "../../AdminPages/ProfilePages/NewAdmin";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import { getFounderStatus } from "../../../services/founderService";
import Tooltip from "../../../Components/Tooltip";
import textData from "../../../textData.json";


function ApprovedFounderDashboard() {
  const { langState } = useContext(LangContext) as LangContextType
  const navigate = useNavigate();
  const founderStatusAsync = useAsync(() => getFounderStatus(), []);
  const { userRegion } = useContext(AuthContext) as AuthContextType;

  if (founderStatusAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (founderStatusAsync.status === "rejected") {
    return <ErrorIndicator />;
  }
 
  const founderStatus = founderStatusAsync.value.founderStatus
  const AdminNotes = founderStatusAsync.value.adminNotes


  return (
    <div className="flex w-full justify-center h-full">
      <div className="relative flex flex-col h-full max-w-maxDashboardContentWidth w-full">
        <ContentPageTopButtons />
        <div className="text-text mb-5 self-baseline w-full">
          <DetailsContentHeader
            label="Your ElevateIP Dashboard"
          />
          {founderStatus === null &&
          <div>
            <div className="flex ">
              <h2 className="font-semibold text-2xl mt-5 mb-5">{textData.Pages.Dashboards.FounderDashboards.ApprovedFounderDashboard.AdminNotes[langState]}</h2>
              <Tooltip content={textData.Pages.Dashboards.FounderDashboards.ApprovedFounderDashboard.Tooltip[langState]} />
            </div>
            <p>{AdminNotes}</p>
          </div>
          }
          {founderStatus === "ipExpertInvite" &&
            <div>
              <h2 className="font-semibold text-2xl mt-5 mb-5">{textData.Pages.Dashboards.FounderDashboards.ApprovedFounderDashboard.NextSteps[langState]}</h2>
              <p>You have been sent an invitation via email to meet with an IP Expert in order to continue your assesments.  Please book an appoitment via the link provided in that email.</p>
              <p>If you have any questions, please reach out to our team at elevateip@communitech.ca</p>
            </div>
          }
          {founderStatus === "growthCoachInvite" &&
            <div>
              <h2 className="font-semibold text-2xl mt-5 mb-5">{textData.Pages.Dashboards.FounderDashboards.ApprovedFounderDashboard.AdminNotes[langState]}</h2>
              <p>You have been sent an invitation via email to meet with a Growth Coach in order to continue your assesments.  Please book an appoitment via the link provided in that email.</p>
              <p>If you have any questions, please reach out to our team at elevateip@communitech.ca</p>      
            </div>
          }
          {
          userRegion === "communitech" &&
          <div className="w-full flex flex-col border border-border p-4">
            <p className=" text-center font-semibold text-lg mb-2">Empower Your IP Journey - NEW ElevateIP Learning Hub is Here!</p>
            <p className="text-center">The ElevateIP Learning Hub will be your central hub for all things intellectual property. Build a strong foundation with a structured knowledge module, access a wealth of resources, and stay current with on-demand webinars. Also, connect and collaborate with fellow learners in a thriving community.</p>
            <p className="font-semibold mt-2 text-center">This platform is your springboard to confidently navigate the world of IP.</p>
            <p className="text-center mt-2">Watch this tutorial for guidance on how to register a new account now!</p>
            <a target="_blank" href="https://drive.google.com/file/d/10ghbQGpJmGfgCdSY2lYNCsEVdwv5YWTh/view" className="underline text-center font-semibold mt-2">ElevateIP Learning Hub Tutorial</a>
            <a target="_blank" href="https://elevateip-learninghub.thinkific.com/" className="underline text-center font-semibold">Register an account here and start learning!</a>
          </div>
          }
      </div>
    </div>
  </div>
  )
}

export default ApprovedFounderDashboard;