import ContentPageTopButtons from "../../../Components/ContentPageTopButtons"
import { useContext } from "react";
import { AuthContext, AuthContextType, LangContext, LangContextType } from "../../../utilities/customHooks";
import Button from "../../../Components/Button";
import { useNavigate } from "react-router-dom";
import textData from "../../../textData.json";

function UnsubmittedFounderDashboard() {

  const navigate = useNavigate();
  const { langState } = useContext(LangContext) as LangContextType
  const { userRegion } = useContext(AuthContext) as AuthContextType;

  return (
    <div className="flex w-full justify-center h-full overflow-y-auto">
      <div className="relative flex flex-col h-full w-dashboardContentWidth max-w-maxDashboardContentWidth">
        <ContentPageTopButtons />
        <div className="text-text flex flex-col items-center">
          <div className='flex flex-col justify-center align-middle mb-10'>
            <h1 className="text-5xl font-bold text-center">{textData.Pages.Dashboards.FounderDashboards.UnsubmittedFounderDashboard.Welcome[langState]}</h1>
            <p className="text-center text-xl mt-5">{textData.Pages.Dashboards.FounderDashboards.UnsubmittedFounderDashboard.Congratulations[langState]}</p>
          </div>
          <div className="flex flex-col w-[95%] justify-start mb-10">
            <p className="font-bold mb-2">ElevateIP is open to any companies that meet the following minimum requirements:</p>
            <ul className="ml-8 mb-2 mt-2 list-disc">
                  <li className="mb-2">You are a Canadian Controlled Private Corporation (CCPC) with less than 500 full time employees worldwide</li>
                  <li className="mb-2">The IP must be owned by the CCPC, not an individual</li>
            </ul>
            <p className="font-bold mb-2">ElevateIP is best for the following companies:</p>
          <ul className="ml-8 mb-2 mt-2 list-disc">
                <li className="mb-2">You are a tech company that uses, improves, develops, or aspires to develop innovative proprietary technology intended to transform your industry/sector</li>
                <li className="mb-2">Your technology stands to benefit the economic and/or social benefit of Canada</li>
                <li className="mb-2">Your primary source of revenue (or intended source, if pre-revenue) derives from the technology being developed, not from services or consulting</li>
                <li className="mb-2">You should have started your go-to-market process and/or demonstrate market potential as well as international expansion potential</li>
                <li className="mb-2">The ownership of your IP clearly resides with the company</li>
                <li className="mb-2">There is a clear plan to commercialize the IP being supported</li>
                <li className="mb-2">The majority of of your full time employees is located in Canada</li>
                <li className="mb-2">Your team is coachable and has grit to move forward</li>
            </ul>
            <p className="text-sm mb-2">Please note that Communitech reserves the right to reject applications at any point in the application process and at our discretion until a statement of work between the company and Communitech has been signed by both parties. Receiving an invitation to meet one of our IP experts or start working on a statement of work does not guarantee funding.</p>
            <p className="font-bold mb-2">{textData.Pages.Dashboards.FounderDashboards.UnsubmittedFounderDashboard.Complete[langState]}</p>
            <ul className="ml-8 mb-5 list-disc">
                <li className="mb-2">{textData.Pages.Dashboards.FounderDashboards.UnsubmittedFounderDashboard.Access[langState]}</li>
                <li className="mb-2">{textData.Pages.Dashboards.FounderDashboards.UnsubmittedFounderDashboard.Edit[langState]}</li>
                <li className="mb-2">{textData.Pages.Dashboards.FounderDashboards.UnsubmittedFounderDashboard.Submit[langState]}</li>
                <li className="mb-2">{textData.Pages.Dashboards.FounderDashboards.UnsubmittedFounderDashboard.Next[langState]}</li>
            </ul>
          </div>

          <Button 
            buttonType={"primary"} 
            buttonText={textData.Pages.Dashboards.FounderDashboards.UnsubmittedFounderDashboard.Button[langState]}
            actionHandler={() => navigate('profile')} 
            style="w-fit self-center"
          />
          <button className='underline text-primary text-center font-bold mt-5'
            onClick={() => {navigate("/help")}}
          >
           {textData.Pages.Dashboards.FounderDashboards.UnsubmittedFounderDashboard.Help2[langState]}
        </button> 
        </div>
        {
          userRegion === "communitech" &&
          <div className="w-full flex flex-col border border-border p-4">
            <p className=" text-center font-semibold text-lg mb-2">Empower Your IP Journey - NEW ElevateIP Learning Hub is Here!</p>
            <p className="text-center">The ElevateIP Learning Hub will be your central hub for all things intellectual property. Build a strong foundation with a structured knowledge module, access a wealth of resources, and stay current with on-demand webinars. Also, connect and collaborate with fellow learners in a thriving community.</p>
            <p className="font-semibold mt-2 text-center">This platform is your springboard to confidently navigate the world of IP.</p>
            <p className="text-center mt-2">Watch this tutorial for guidance on how to register a new account now!</p>
            <a target="_blank" href="ElevateIP Learning Hub Tutorial" className="underline font-semibold mt-2">ElevateIP Learning Hub Tutorial</a>
            <a target="_blank" href="ElevateIP Learning Hub Tutorial" className="underline font-semibold">ElevateIP Learning Hub Tutorial</a>
          </div>
          }
      </div>
    </div>
  )
}

export default UnsubmittedFounderDashboard;