import { useContext } from "react";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons"
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { getLatestMembershipApplication, getNewMemberAcceptanceStatus } from "../../../services/founderService";
import { AuthContext, AuthContextType, LangContext, LangContextType, useAsync } from "../../../utilities/customHooks";
import * as T from "../../../utilities/frontendTypes";
import textData from "../../../textData.json";
import { useNavigate } from "react-router-dom";

function PendingFounderDashboard() {
  const { langState } = useContext(LangContext) as LangContextType
  const navigate = useNavigate();
  const { userRegion } = useContext(AuthContext) as AuthContextType;
  

  const latestMembershipApplicationAsync = useAsync(() => getLatestMembershipApplication(), []);
  const newMemberAcceptanceStatusAsync = useAsync(() => getNewMemberAcceptanceStatus(), []);
   
  if (latestMembershipApplicationAsync.status === "pending" || newMemberAcceptanceStatusAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (latestMembershipApplicationAsync.status === "rejected" || newMemberAcceptanceStatusAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const newMemberAcceptanceStatus = newMemberAcceptanceStatusAsync.value.data;

  const latestMembershipApplication = latestMembershipApplicationAsync.value.data as T.ApplicationDetails;

  const latestApplicationSubmissionDate = new Date(latestMembershipApplication.createdAt).getTime();
  const today = new Date().getTime() + 1;
  const daysApart = Math.floor((today - latestApplicationSubmissionDate) / (1000 * 60 * 60 * 24));

  const firstName = latestMembershipApplication.profileSnapshot.personalInfo?.firstName;

  return (
    <div className="flex w-full justify-center h-full">
      <div className="relative flex flex-col h-full w-dashboardContentWidth max-w-maxDashboardContentWidth">
        <ContentPageTopButtons />
        <div className="text-text flex flex-col items-center w-full">
          <div className='flex flex-col justify-center align-middle mb-8'>
            <h1 className="text-4xl font-bold text-center">{textData.Pages.Dashboards.FounderDashboards.PendingFounderDashboard.Welcome[langState]}{firstName ? <span className="capitalize">, {firstName}</span> : ""}!</h1>
            <p className="text-center text-3xl mt-5">{textData.Pages.Dashboards.FounderDashboards.PendingFounderDashboard.Received[langState]}</p>
          </div>
          <div className="flex flex-col w-[85%] max-w-[850px]">
            {
              newMemberAcceptanceStatus === "active"
              ?
              <>
                <p className="mb-5 text-center text-2xl">{textData.Pages.Dashboards.FounderDashboards.PendingFounderDashboard.Evaluation[langState]}</p>
                <p className="mb-5 text-center text-xl">Application Submitted: {daysApart === 0 ? <span className="font-bold">Today</span> : daysApart === 0 ? <span className="font-bold">Yesterday</span> : <span className="font-bold">{daysApart} days ago</span>}.</p>
                <button className='underline text-primary text-center font-bold mt-10'
                  onClick={() => {navigate("/help")}}
                >
                  {textData.Pages.Dashboards.FounderDashboards.UnsubmittedFounderDashboard.Help2[langState]}
                </button> 
              </>
              :
              <>
                <p className="font-semibold">{textData.Pages.Dashboards.FounderDashboards.PendingFounderDashboard.NotAccessing[langState]}</p>
              </>
            }
          </div>
        </div>
        {
          userRegion === "communitech" &&
          <div className="w-full flex flex-col border border-border p-4">
            <p className=" text-center font-semibold text-lg mb-2">Empower Your IP Journey - NEW ElevateIP Learning Hub Launching Soon!</p>
            <p className="text-center">The ElevateIP Learning Hub will be your central hub for all things intellectual property. Build a strong foundation with a structured knowledge module, access a wealth of resources, and stay current with on-demand webinars. Also, connect and collaborate with fellow learners in a thriving community.</p>
            <p className="font-semibold mt-2 text-center">This platform is your springboard to confidently navigate the world of IP.</p>
            <p className="text-center mt-2">Watch this tutorial for guidance on how to register a new account now!</p>
            <a target="_blank" href="ElevateIP Learning Hub Tutorial" className="underline font-semibold mt-2">ElevateIP Learning Hub Tutorial</a>
            <a target="_blank" href="ElevateIP Learning Hub Tutorial" className="underline font-semibold">ElevateIP Learning Hub Tutorial</a>
          </div>
        }
      </div>
    </div>
  )
}

export default PendingFounderDashboard